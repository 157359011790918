import { notification } from '@lavka/ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import type { Entity } from '~cache/cache';
import { useCache } from '~cache/useCache';
import { createCustomI18n } from '~shared/components/DataLanguageSelector/i18n';
import useLoadData from '~shared/hooks/useLoadData';
import type { ProductGroups } from '~types/productGroups';
import { getProductGroupChildren, useProductGroupChildren } from '~zustand/productGroupChildren';
import { useCheckExp, useUser } from '~zustand/userData';

import { fetchProductGroupsData } from './utils';

const FIELDS_TO_LOAD = ['group_id', 'parent_group_id', 'name', 'status', 'vars'] as const;

export interface GroupEntry extends ProductGroups.ProductGroup {
	children?: (GroupEntry | ProductGroups.ProductGroup)[];
	path?: string[];
	key: string;
	title: string;
	value: string;
	has_child?: boolean;
	isLeaf?: boolean;
	products_scope?: string[];
}

export default function () {
	const [t] = useTranslation();
	const user = useUser();
	const isExpSeparateDataLang = useCheckExp('exp_separate_data_lang');
	const customI18n = useMemo(
		() => createCustomI18n({ lng: user?.data_language }),
		[user?.data_language, isExpSeparateDataLang]
	);

	const cache = useCache({
		productGroups: {
			ids: [],
			_fields: FIELDS_TO_LOAD,
		},
	});

	const result = useLoadData(
		() =>
			fetchProductGroupsData(
				{
					ids: Object.keys(cache.productGroups),
					_fields: ['name'] as keyof Entity<'products'>['_fields'],
				},
				isExpSeparateDataLang
			),
		[customI18n?.options?.lng],
		!isExpSeparateDataLang
	);

	const productGroupsChildrenMap = useProductGroupChildren();

	const [tree, setTree] = useState<GroupEntry[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const rootProductGroups = tree.map((group) => {
		const rest = { ...group };
		delete rest.children;
		delete rest.isLeaf;
		return rest as GroupEntry;
	});

	useEffect(() => {
		const newTree = convertGroupsToTree();
		setTree(newTree);
	}, [productGroupsChildrenMap, cache.productGroups, customI18n?.options?.lng, result.data]);

	const dictionary = Object.values(cache.productGroups).reduce(
		(result, group) => {
			if (group && typeof group !== 'boolean') {
				result[group?.group_id] = group?.name;
			}
			return result;
		},
		{} as Record<string, string>
	);

	const addPropsToGroup = (group: ProductGroups.ProductGroup): GroupEntry => {
		if (!group) return group;

		return {
			...group,
			key: group.group_id,
			value: group.group_id,
			title: group.name,
			children: productGroupsChildrenMap[group.group_id] !== null ? group.children || [] : undefined,
			isLeaf: productGroupsChildrenMap[group.group_id] === null,
		};
	};

	const convertGroupsToTree = useCallback(() => {
		const roots: GroupEntry[] = [];
		const groupsMap: Record<string, GroupEntry> = {};
		for (const id in cache.productGroups) {
			groupsMap[id] = addPropsToGroup(cache.productGroups[id]! as ProductGroups.ProductGroup);
		}
		const groups: GroupEntry[] = Object.values(groupsMap);

		groups.forEach((group: GroupEntry) => {
			if (!group) return;

			if (!group.parent_group_id) {
				roots.push(group);
			} else {
				groupsMap[group.parent_group_id].children = [...(groupsMap[group.parent_group_id].children || []), group];
			}
		});

		return roots;
	}, [cache.productGroups, customI18n?.options?.lng]);

	const expandProductGroupInTable = async (expanded: boolean, group: GroupEntry) => {
		if (!expanded || !group.children) return;
		if (Array.isArray(group.children) && group.children.length) return;
		setLoading(true);
		try {
			await getProductGroupChildren(group.group_id);
		} catch {
			notification.error({
				message: t('Не удалось получить группу продуктов'),
			});
		} finally {
			setLoading(false);
		}
	};

	const addProductGroups = async (group: any) => {
		setLoading(true);
		try {
			await getProductGroupChildren(group.group_id);
		} catch {
			notification.error({
				message: t('Не удалось получить группу продуктов'),
			});
		} finally {
			setLoading(false);
		}
	};

	const getProductGroupPath = (group: string) => {
		if (group === null) return null;
		const path: string[] = [];
		let parentId = cache.productGroups[group]?.parent_group_id;
		while (parentId) {
			path.push(parentId);
			parentId = cache.productGroups[parentId]?.parent_group_id;
		}
		return path.reverse();
	};

	const addProductGroupsById = async (groupIds: string | string[]) => {
		const newIds = (Array.isArray(groupIds) ? groupIds : [groupIds]).filter((id) => !cache.productGroups[id]);

		if (!newIds.length) {
			return;
		}

		setLoading(true);

		try {
			const { data } = await api.productGroups.load({
				group_id: newIds,
				hierarchy: true,
				_fields: FIELDS_TO_LOAD,
			});

			cache.addData({
				entityName: 'productGroups',
				data: data.result,
				fields: FIELDS_TO_LOAD,
			});
		} catch {
			notification.error({
				message: t('Не удалось получить группу продуктов'),
			});
		} finally {
			setLoading(false);
		}
	};

	const groupsDictionary = useMemo(
		() =>
			Object.keys(cache.productGroups).reduce(
				(dict, id) => {
					const productGroup = cache.productGroups[id];
					if (productGroup) {
						dict[id] = productGroup.name;
					}
					return dict;
				},
				{} as Record<string, string>
			),
		[cache.productGroups, customI18n.options.lng]
	);

	return {
		loading,
		dictionary,
		productGroupsTree: tree,
		rootProductGroups,
		productGroupsById: cache.productGroups,
		productGroupsChildrenMap,
		groupsDictionary,
		expandProductGroupInTable,
		addProductGroups,
		addProductGroupsById,
		addPropsToGroup,
		getProductGroupPath,
	};
}
