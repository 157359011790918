import { notification } from '@lavka/ui-kit';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import api from '~/api';
import i18n from '~/i18n';
import type { Couriers } from '~types/couriers';
import type { Events } from '~types/events';

type State = {
	couriers: Record<Couriers.CourierId, Couriers.CourierBrief>;
	addCouriers: (courierIds: string[]) => void;
	getCourierCheckins: ({ storeId }: { storeId?: string }) => void;
	updateCouriers: (couries: Couriers.CourierBrief[] | Events.CourierEvent[]) => void;
};

const initialState = {
	couriers: {},
};

export const couriersStore = create<State>()(
	devtools(
		immer((set) => ({
			...initialState,
			addCouriers: async (courierIds: string[]) => {
				if (!courierIds.length) return;
				try {
					const { data } = await api.couriers.loadBrief(
						{ courier_id: courierIds },
						{ disableDefaultNotification: [403] }
					);

					set((state) => {
						data.result.map((courier) => {
							state.couriers[courier.courier_id] = {
								...state.couriers[courier.courier_id],
								...courier,
							};
						});
						return state;
					});
				} catch {
					notification.error({
						message: i18n.t('Не удалось загрузить данные курьеров'),
					});
				}
			},
			updateCouriers: (couriers: Couriers.CourierBrief[] | Events.CourierEvent[]) => {
				set((state) => {
					couriers.map((courier) => {
						if (state.couriers[courier.courier_id]) {
							state.couriers[courier.courier_id] = {
								...state.couriers[courier.courier_id],
								...courier,
							};
						}
					});
					return state;
				});
			},
			getCourierCheckins: async ({ storeId }: { storeId?: string }) => {
				if (!storeId) return;
				try {
					const { data: checkinsData } = await api.couriers.checkins({ store_id: storeId });

					if (!checkinsData.couriers.length) {
						set(() => ({ couriers: {} }));
						return;
					}

					const { data: loadBriefData } = await api.couriers.loadBrief({
						courier_id: checkinsData.couriers.map((courier) => courier.courier_id),
					});

					const couriers = loadBriefData.result.reduce<Record<Couriers.CourierId, Couriers.CourierBrief>>(
						(map, courier) => {
							map[courier.courier_id] = courier;
							return map;
						},
						{}
					);

					set(() => ({ couriers }));
				} catch {
					notification.error({
						message: i18n.t('Не удалось загрузить данные курьеров'),
					});
				}
			},
		})),
		{ name: 'pageInfoData' }
	)
);

// Это в компонентах
export const useCouriers = () =>
	couriersStore((state) => ({
		couriers: Object.values(state.couriers),
	}));

// Это в функции
export const getCouriers = () => ({ couriers: couriersStore.getState().couriers });

export const addCouriers = (courierIds: string[]) => couriersStore.getState().addCouriers(courierIds);
export const updateCouriers = (couries: Couriers.CourierBrief[] | Events.CourierEvent[]) =>
	couriersStore.getState().updateCouriers(couries);
export const getCourierCheckins = ({ storeId }: { storeId?: string }) =>
	couriersStore.getState().getCourierCheckins({ storeId });
