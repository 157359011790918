import { notification } from '@lavka/ui-kit';
import type { DependencyList } from 'react';
import { useState } from 'react';

import api from '~/api';
import i18n from '~/i18n';
import type { ConfigsLoadRequestArgument, ExpConfigsResponse } from '~types/configs';
import { useCheckSubPermit } from '~zustand/userData';

import { useAsyncEffect } from './useAsyncEffect';

export type UseGetConfigsInput = {
	type: ConfigsLoadRequestArgument['type'];
	id?: string;
	group: string;
	name?: ConfigsLoadRequestArgument['name'];
};

export default function useGetConfigs(input: UseGetConfigsInput, deps?: DependencyList, skip = false) {
	const [configs, setConfigs] = useState<ExpConfigsResponse>();
	const [isLoading, toggleIsLoading] = useState(false);
	const [isFetched, setIsFetched] = useState(false);

	const isExpSubPermitOfConfigsLoad = useCheckSubPermit('configs_load', input.group);

	useAsyncEffect(async () => {
		if (!input.id || skip || !isExpSubPermitOfConfigsLoad) {
			return;
		}

		toggleIsLoading(true);

		try {
			// eslint-disable-next-line no-restricted-syntax
			const { data } = await api.configs.load({ ...input, id: input.id! });

			setConfigs(data.result);
		} catch {
			notification.error({
				message: i18n.t('Не удалось получить настройки'),
			});
		} finally {
			setIsFetched(true);
			toggleIsLoading(false);
		}
	}, deps ?? []);

	return { configs, isLoading, isFetched };
}
