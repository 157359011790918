import dayjs from 'dayjs';

import { MILESTONES } from '~shared/components/MenteeMentorField/const';
import MenteesField from '~shared/components/MenteeMentorField/MenteesField';
import type { UserData } from '~shared/components/MenteeMentorField/MentorsField';
import MentorsField from '~shared/components/MenteeMentorField/MentorsField';
import type { User } from '~types/user';

const MenteeMentorField = ({
	user,
	menteesData,
	usersData,
	updateRerenderKey,
	usersMentor,
}: {
	user?: UserData;
	usersMentor?: UserData;
	menteesData: UserData[];
	usersData: User.User[];
	updateRerenderKey: () => void;
}) => {
	const canBeMentor = dayjs().isAfter(dayjs(user?.created).add(MILESTONES.canBeMentorAfter, 'day'));

	return (
		<>
			{dayjs().isBefore(
				user?.vars?.mentoring_until
					? dayjs(user.vars?.mentoring_until).add(13, 'day')
					: dayjs(user?.created).add(MILESTONES.canBeMentoredBefore, 'day')
			) &&
				['executer_junior', 'executer'].includes(user?.role) && (
					<MenteesField
						user={user}
						usersData={usersData}
						updateRerenderKey={updateRerenderKey}
						mentorData={usersMentor}
					/>
				)}

			{((canBeMentor && user?.role === 'executer') || ['store_admin', 'vice_store_admin'].includes(user?.role)) && (
				<MentorsField
					user={user}
					menteesData={menteesData}
					usersData={usersData}
					updateRerenderKey={updateRerenderKey}
				/>
			)}
		</>
	);
};

export default MenteeMentorField;
