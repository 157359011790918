import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { Shifts } from '~types/shifts';

export type ProcessedCourierCardData = {
	name: string;
	courier_id?: string;
	external_id?: string;
	status?: string;
	docNumbers: string[];
	deliveryPromise: string | string[];
	created: string;
	lastOrderTime?: string;
	checkin_time?: string;
	delivery_type: Shifts.DeliveryType | null;
	is_rover?: boolean;
};

export type CouriersByStatusData = {
	columns: {
		free: ProcessedCourierCardData[];
		returning: ProcessedCourierCardData[];
		pause: ProcessedCourierCardData[];
		deliver: ProcessedCourierCardData[];
		delivering: ProcessedCourierCardData[];
		collecting: ProcessedCourierCardData[];
		noStatus: ProcessedCourierCardData[];
	};
	activeCouriers: number;
	allCouriers: number;
};

export const deliveryStatuses = makeDynamicTranslations({
	free: tDynamic('Свободные'),
	returning: tDynamic('Возвращаются'),
	pause: tDynamic('На паузе'),
	deliver: tDynamic('Передают заказ'),
	delivering: tDynamic('На пути к клиенту'),
	collecting: tDynamic('Забирают заказ'),
});

// Список статусов курьеров для вывода в табло, моб табло и карточке склада. Порядок не меняем.
export const deliveryStatusesArray = ['free', 'returning', 'pause', 'deliver', 'delivering', 'collecting'] as const;
