// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type OrderSource =
	| 'dispatcher'
	| 'tsd'
	| 'eda'
	| '1c'
	| 'integration'
	| 'external'
	| 'original_external'
	| 'woody'
	| 'tristero'
	| 'internal'
	| 'zoho'
	| 'aws'
	| 'autoorder';
export const orderSourceValues = [
	'dispatcher',
	'tsd',
	'eda',
	'1c',
	'integration',
	'external',
	'original_external',
	'woody',
	'tristero',
	'internal',
	'zoho',
	'aws',
	'autoorder',
] as OrderSource[];
export const orderSourceNames = makeServerTranslations(orderSourceValues, 'enums.ORDER_SOURCE');

export type OrderStatus =
	| 'reserving'
	| 'approving'
	| 'request'
	| 'processing'
	| 'delivery'
	| 'complete'
	| 'canceled'
	| 'failed';
export const orderStatusValues = [
	'reserving',
	'approving',
	'request',
	'processing',
	'delivery',
	'complete',
	'canceled',
	'failed',
] as OrderStatus[];
export const orderStatusNames = makeServerTranslations(orderStatusValues, 'enums.ORDER_STATUS');

export type OrderType =
	| 'order'
	| 'sub_order_kitchen'
	| 'sub_order_coffee'
	| 'sub_order_parcels'
	| 'sub_order_external_market_parcels'
	| 'sub_order_units'
	| 'sub_order_robot'
	| 'order_retail'
	| 'acceptance'
	| 'acceptance_market'
	| 'assets_writeoff'
	| 'shipment'
	| 'shipment_rollback'
	| 'stowage'
	| 'stowage_market'
	| 'sale_stowage'
	| 'weight_stowage'
	| 'move'
	| 'writeoff'
	| 'check'
	| 'check_product_on_shelf'
	| 'check_more'
	| 'check_final'
	| 'regrading'
	| 'writeoff_prepare_day'
	| 'check_valid_short'
	| 'check_valid_regular'
	| 'visual_control'
	| 'repacking'
	| 'turnoff_starbucks'
	| 'hide_and_show'
	| 'stop_list'
	| 'refund'
	| 'part_refund'
	| 'inventory'
	| 'inventory_check_more'
	| 'inventory_check_product_on_shelf'
	| 'control_check'
	| 'cargo_move'
	| 'cargo_stowage'
	| 'cargo_collect'
	| 'manual_correction'
	| 'collect'
	| 'hand_move'
	| 'kitchen_prod'
	| 'kitchen_provision'
	| 'cash_collect_courier'
	| 'cash_collect_store'
	| 'buy_stocks'
	| 'sell_stocks'
	| 'robot_provision'
	| 'complaint';
export const orderTypeValues = [
	'order',
	'sub_order_kitchen',
	'sub_order_coffee',
	'sub_order_parcels',
	'sub_order_external_market_parcels',
	'sub_order_units',
	'sub_order_robot',
	'order_retail',
	'acceptance',
	'acceptance_market',
	'assets_writeoff',
	'shipment',
	'shipment_rollback',
	'stowage',
	'stowage_market',
	'sale_stowage',
	'weight_stowage',
	'move',
	'writeoff',
	'check',
	'check_product_on_shelf',
	'check_more',
	'check_final',
	'regrading',
	'writeoff_prepare_day',
	'check_valid_short',
	'check_valid_regular',
	'visual_control',
	'repacking',
	'turnoff_starbucks',
	'hide_and_show',
	'stop_list',
	'refund',
	'part_refund',
	'inventory',
	'inventory_check_more',
	'inventory_check_product_on_shelf',
	'control_check',
	'cargo_move',
	'cargo_stowage',
	'cargo_collect',
	'manual_correction',
	'collect',
	'hand_move',
	'kitchen_prod',
	'kitchen_provision',
	'cash_collect_courier',
	'cash_collect_store',
	'buy_stocks',
	'sell_stocks',
	'robot_provision',
	'complaint',
] as OrderType[];
export const orderTypeNames = makeServerTranslations(orderTypeValues, 'enums.ORDER_TYPE');

export type OrderProblemType =
	| 'low'
	| 'found_low'
	| 'confirm_assembled_products'
	| 'shelf_not_found'
	| 'product_not_found'
	| 'product_duplicate'
	| 'product_without_components'
	| 'product_is_not_asset'
	| 'product_in_kitchen_assortment'
	| 'empty_products'
	| 'empty_shelves'
	| 'product_reserved'
	| 'have_stocks'
	| 'stock_changed'
	| 'required_is_empty'
	| 'bad_required'
	| 'store_is_not_inventory'
	| 'child_order_incomplete'
	| 'human_failed'
	| 'invalid_order_format'
	| 'item_not_found'
	| 'wrong_item_count'
	| 'item_duplicate'
	| 'assortment_not_found'
	| 'assortment_not_active'
	| 'src_shelf_not_found'
	| 'dst_shelf_not_found'
	| 'no_move_detected'
	| 'required_type_mix'
	| 'required_duplicate'
	| 'required_weight_wrong'
	| 'required_too_big'
	| 'non_weight_product'
	| 'shelf_conflict_weight_product'
	| 'different_products_scope'
	| 'different_products_group'
	| 'invalid_product_status'
	| 'store_in_inventory_mode'
	| 'assortment_contractor_not_found'
	| 'assortment_contractor_product_not_found'
	| 'check_product_not_in_acceptance'
	| 'not_weight_parent'
	| 'move_quant_to_noquant'
	| 'move_quant_to_quant'
	| 'move_diff_shelf_types'
	| 'non_quants_product'
	| 'not_a_whole_pack'
	| 'non_component_shelf'
	| 'stock_not_found_for_product'
	| 'stock_found_for_item'
	| 'no_check_kitchen_product'
	| 'repair_task_not_found'
	| 'repair_task_not_in_progress'
	| 'repair_task_another_store'
	| 'non_consumable_product_to_office'
	| 'too_many_diff_products'
	| 'repacking_many_products'
	| 'rack_zone_not_found'
	| 'rack_not_found'
	| 'rack_reserve_failure'
	| 'cargo_is_blocked'
	| 'api_call_failure'
	| 'many_shelves'
	| 'no_experiment'
	| 'transfer_act_diff'
	| 'no_waybill'
	| 'lot_have_invalid_items'
	| 'not_closed_documents'
	| 'orders_in_delivery'
	| 'store_in_wrong_status'
	| 'low_ingridients'
	| 'demand_already_satisfied'
	| 'zero_demand'
	| 'forbidden_active_orders'
	| 'stock_in_another_stop_list';
export const orderProblemTypeValues = [
	'low',
	'found_low',
	'confirm_assembled_products',
	'shelf_not_found',
	'product_not_found',
	'product_duplicate',
	'product_without_components',
	'product_is_not_asset',
	'product_in_kitchen_assortment',
	'empty_products',
	'empty_shelves',
	'product_reserved',
	'have_stocks',
	'stock_changed',
	'required_is_empty',
	'bad_required',
	'store_is_not_inventory',
	'child_order_incomplete',
	'human_failed',
	'invalid_order_format',
	'item_not_found',
	'wrong_item_count',
	'item_duplicate',
	'assortment_not_found',
	'assortment_not_active',
	'src_shelf_not_found',
	'dst_shelf_not_found',
	'no_move_detected',
	'required_type_mix',
	'required_duplicate',
	'required_weight_wrong',
	'required_too_big',
	'non_weight_product',
	'shelf_conflict_weight_product',
	'different_products_scope',
	'different_products_group',
	'invalid_product_status',
	'store_in_inventory_mode',
	'assortment_contractor_not_found',
	'assortment_contractor_product_not_found',
	'check_product_not_in_acceptance',
	'not_weight_parent',
	'move_quant_to_noquant',
	'move_quant_to_quant',
	'move_diff_shelf_types',
	'non_quants_product',
	'not_a_whole_pack',
	'non_component_shelf',
	'stock_not_found_for_product',
	'stock_found_for_item',
	'no_check_kitchen_product',
	'repair_task_not_found',
	'repair_task_not_in_progress',
	'repair_task_another_store',
	'non_consumable_product_to_office',
	'too_many_diff_products',
	'repacking_many_products',
	'rack_zone_not_found',
	'rack_not_found',
	'rack_reserve_failure',
	'cargo_is_blocked',
	'api_call_failure',
	'many_shelves',
	'no_experiment',
	'transfer_act_diff',
	'no_waybill',
	'lot_have_invalid_items',
	'not_closed_documents',
	'orders_in_delivery',
	'store_in_wrong_status',
	'low_ingridients',
	'demand_already_satisfied',
	'zero_demand',
	'forbidden_active_orders',
	'stock_in_another_stop_list',
] as OrderProblemType[];
export const orderProblemTypeNames = makeServerTranslations(orderProblemTypeValues, 'enums.ORDER_PROBLEM_TYPE');

export type OrderTargetStatuses = 'complete' | 'canceled' | 'failed';
export const orderTargetStatusesValues = ['complete', 'canceled', 'failed'] as OrderTargetStatuses[];
export const orderTargetStatusesNames = makeServerTranslations(
	orderTargetStatusesValues,
	'enums.ORDER_TARGET_STATUSES'
);

export type OrderCheckValidMode =
	| 'store2trash'
	| 'store2markdown'
	| 'eatToday2markdown'
	| 'markdown2trash'
	| 'store2review'
	| 'review2trash';
export const orderCheckValidModeValues = [
	'store2trash',
	'store2markdown',
	'eatToday2markdown',
	'markdown2trash',
	'store2review',
	'review2trash',
] as OrderCheckValidMode[];
export const orderCheckValidModeNames = makeServerTranslations(
	orderCheckValidModeValues,
	'enums.ORDER_CHECK_VALID_MODE'
);

export type OrderDocumentStatus = 'scan' | 'processing' | 'rescan' | 'done';
export const orderDocumentStatusValues = ['scan', 'processing', 'rescan', 'done'] as OrderDocumentStatus[];
export const orderDocumentStatusNames = makeServerTranslations(
	orderDocumentStatusValues,
	'enums.ORDER_DOCUMENT_STATUS'
);

export type ExtraWayPoints = 'pharmacy' | 'item_pick_up_point' | 'pet_store';
export const extraWayPointsValues = ['pharmacy', 'item_pick_up_point', 'pet_store'] as ExtraWayPoints[];
export const extraWayPointsNames = makeServerTranslations(extraWayPointsValues, 'enums.EXTRA_WAY_POINTS');

export type OrderAttrShelfPickingMethod = 'delete_items' | 'replace_items';
export const orderAttrShelfPickingMethodValues = ['delete_items', 'replace_items'] as OrderAttrShelfPickingMethod[];
export const orderAttrShelfPickingMethodNames = makeServerTranslations(orderAttrShelfPickingMethodValues);

export type InvoiceType = 'bill' | 'payment' | 'refund';
export const invoiceTypeValues = ['bill', 'payment', 'refund'] as InvoiceType[];
export const invoiceTypeNames = makeServerTranslations(invoiceTypeValues, 'enums.INVOICE_TYPE');

export type InvoicePaymentMethodType = 'cash' | 'online';
export const invoicePaymentMethodTypeValues = ['cash', 'online'] as InvoicePaymentMethodType[];
export const invoicePaymentMethodTypeNames = makeServerTranslations(
	invoicePaymentMethodTypeValues,
	'enums.INVOICE_PAYMENT_METHOD_TYPE'
);

export type StopListReasons =
	| 'equipment_broken'
	| 'planed_maintenance'
	| 'no_electricity'
	| 'door_blocked'
	| 'deep_fryer_service'
	| 'chef_is_late'
	| 'no_chef_at_all'
	| 'chef_left_early'
	| 'no_water';
export const stopListReasonsValues = [
	'equipment_broken',
	'planed_maintenance',
	'no_electricity',
	'door_blocked',
	'deep_fryer_service',
	'chef_is_late',
	'no_chef_at_all',
	'chef_left_early',
	'no_water',
] as StopListReasons[];
export const stopListReasonsNames = makeServerTranslations(stopListReasonsValues, 'enums.STOP_LIST_REASONS');

export type OrderConditionsOctopusOrder = 'parent' | 'child';
export const orderConditionsOctopusOrderValues = ['parent', 'child'] as OrderConditionsOctopusOrder[];
export const orderConditionsOctopusOrderNames = makeServerTranslations(orderConditionsOctopusOrderValues);

export type OrderCourierType = 'human' | 'rover';
export const orderCourierTypeValues = ['human', 'rover'] as OrderCourierType[];
export const orderCourierTypeNames = makeServerTranslations(orderCourierTypeValues);

export type OrderDeliveryStatus =
	| 'UNCONFIRMED'
	| 'AWAITING_CARD_PAYMENT'
	| 'CALL_CENTER_CONFIRMED'
	| 'PLACE_CONFIRMED'
	| 'WAITING_ASSIGNMENTS'
	| 'COURIER_ASSIGNED'
	| 'READY_FOR_DELIVERY'
	| 'ORDER_TAKEN'
	| 'ARRIVED_TO_CUSTOMER'
	| 'CUSTOMER_NO_SHOW'
	| 'PICKUP'
	| 'UNKNOWN'
	| 'DELIVERED'
	| 'CANCELLED';
export const orderDeliveryStatusValues = [
	'UNCONFIRMED',
	'AWAITING_CARD_PAYMENT',
	'CALL_CENTER_CONFIRMED',
	'PLACE_CONFIRMED',
	'WAITING_ASSIGNMENTS',
	'COURIER_ASSIGNED',
	'READY_FOR_DELIVERY',
	'ORDER_TAKEN',
	'ARRIVED_TO_CUSTOMER',
	'CUSTOMER_NO_SHOW',
	'PICKUP',
	'UNKNOWN',
	'DELIVERED',
	'CANCELLED',
] as OrderDeliveryStatus[];
export const orderDeliveryStatusNames = makeServerTranslations(
	orderDeliveryStatusValues,
	'enums.ORDER_DELIVERY_STATUS'
);

export type OrderLogSources =
	| 'save'
	| 'ack'
	| 'approve'
	| 'suggest_done'
	| 'suggest_error'
	| 'signal'
	| 'signal_done'
	| 'set_courier'
	| 'set_eda_status'
	| 'set_pause'
	| 'grocery_dispatch'
	| 'reopen'
	| 'cancel'
	| 'cancel_internal'
	| 'cancel_external'
	| 'cancel_user'
	| 'hds_task_callback';
export const orderLogSourcesValues = [
	'save',
	'ack',
	'approve',
	'suggest_done',
	'suggest_error',
	'signal',
	'signal_done',
	'set_courier',
	'set_eda_status',
	'set_pause',
	'grocery_dispatch',
	'reopen',
	'cancel',
	'cancel_internal',
	'cancel_external',
	'cancel_user',
	'hds_task_callback',
] as OrderLogSources[];
export const orderLogSourcesNames = makeServerTranslations(orderLogSourcesValues, 'enums.ORDER_LOG_SOURCES');

export type LogRecordVarsAction = 'assign' | 'unassign';
export const logRecordVarsActionValues = ['assign', 'unassign'] as LogRecordVarsAction[];
export const logRecordVarsActionNames = makeServerTranslations(logRecordVarsActionValues);

export type LogRecordVarsCode = 'OK' | 'FAIL';
export const logRecordVarsCodeValues = ['OK', 'FAIL'] as LogRecordVarsCode[];
export const logRecordVarsCodeNames = makeServerTranslations(logRecordVarsCodeValues);
