import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import api, { setUserRoleHeader } from '~/api';
import { appVersion } from '~/appConfig';
import i18n from '~/i18n';
import type { Language } from '~i18n/types';
import { findSubscription } from '~shared/utils/ev';
import { updateUserParams } from '~shared/utils/eventMetrikaService';
import { logout } from '~shared/utils/loginFunctions';
import { logAxiosError } from '~shared/utils/logs';
import { updateAdditional } from '~shared/utils/rum';
import type { User } from '~types/user';
import type { ExpName } from '~types/userConfig';

import { changeJoinedStore } from './actions/userData';

type State = {
	userData: User.UserData;
	addUserData: (payload: User.UserData) => void;
	updateOnlyUser: (payload: User.User) => void;
	getUserData: (payload?: boolean) => Promise<void>;
	checkExp: (exps: ExpName[]) => boolean;
	checkPermit: (permits: string[]) => boolean;
	checkFieldPermit: (fieldName: string, pageName: string, permitName: string) => boolean;
	getPermitValue: (permitName: string, permits: User.Permit[]) => User.PermitValue | undefined;
	checkSubPermit: (permitName: string, subPermitName: string) => boolean;
	checkPermitGroup: (name: string) => boolean;
	checkSubPermitGroup: (name: string, optionName: string) => boolean;
	checkFieldPermitGroup: (permitName: string, fieldName: string, pageName: string) => boolean;
};

const initialState = {
	permits: [],
	permit_groups: {},
	constants: {
		backend_version: '',
		providers: [],
		domain_configs: {
			urls: {},
		},
	},
	user: {},
	executers: [],
} as User.UserData;

export const useUserStore = create<State>()(
	devtools(
		(set, get) => ({
			userData: initialState,
			addUserData: (payload: User.UserData) => set(() => ({ userData: payload }), false, 'addUserData'),
			updateOnlyUser: (payload: User.User) =>
				set((state) => ({ userData: { ...state.userData, user: payload } }), false, 'updateOnlyUser'),
			getUserData: async (updateStore?: boolean) => {
				try {
					const options = await api.profile.options();
					if (options.data.user.role !== 'guest') {
						await i18n.changeLanguage(options.data.user.lang?.replace('_', '-'));
					} else {
						// Для гостя язык берем из браузера
						options.data.user.lang = i18n.language.replace('-', '_') as Language;
					}

					const storeId = options.data.user.store_id;
					if (updateStore && storeId && !findSubscription(['order', 'store', storeId])) {
						await changeJoinedStore(storeId);
					}

					set({ userData: options.data }, false, 'getUserData');

					if (options.data.user.role) {
						setUserRoleHeader(options.data.user.role);
					}

					const userId = options.data.user.user_id;
					const additional = {
						userRole: options.data.user.role,
						userStoreId: options.data.user.store_id,
						userLang: options.data.user.lang,
						backendVersion: options.data.constants.backend_version,
						frontendVersion: appVersion,
					};

					updateAdditional({ ...additional, userId });
					updateUserParams(userId, additional);
				} catch (error) {
					logAxiosError(error);
					if ([401, 403].includes(error.status)) {
						logout(false, '/login');
					}
				}
			},
			checkExp: (exps: ExpName[]) => {
				if (exps.length === 0) {
					return true;
				}

				const constants = get().userData.constants;

				if (!constants.configs?.exp) {
					return false;
				}

				return exps.every((currentExp) => !!constants.configs?.exp[currentExp]?.enabled);
			},
			checkPermit: (permits: string[]) => {
				if (permits.length === 0) {
					return true;
				}

				const user = get().userData;

				return permits.every((permitName) => {
					if (!user.user.store_id && ['users', 'shelves'].includes(permitName)) {
						return false;
					}

					const permitValue = user.permits.find((permit) => permit.name === permitName)?.value;

					if (permitName === 'configs_load') {
						return Array.isArray(permitValue) && permitValue.length;
					}

					return !!permitValue;
				});
			},
			checkFieldPermit: (fieldName: string, pageName: string, permitName: string = 'save') => {
				const permits = get().userData.permits;

				// @ts-ignore
				const pageFieldsPermits: string[] = permits.find((permit) => permit.name === permitName)?.value?.[pageName];

				if (pageFieldsPermits) {
					if (pageName === 'store' && permitName === 'save' && fieldName === 'external_id') {
						return true;
					}
					return pageFieldsPermits.includes(fieldName);
				}
				return true;
			},
			getPermitValue: (permitName: string, permits: User.Permit[] = []) => {
				return permits.find((permit) => permit.name === permitName)?.value;
			},
			checkSubPermit(permitName: string, subPermitName: string) {
				const permits = get().userData.permits;
				const subPermits = get().getPermitValue(permitName, permits);

				return Array.isArray(subPermits) && subPermits.includes(subPermitName);
			},
			checkPermitGroup: (name: string) => {
				const permitGroup = get().userData.permit_groups?.[name];

				if (!permitGroup) return false;

				return typeof permitGroup === 'boolean' ? permitGroup : Object.values(permitGroup).every((e) => !!e);
			},
			checkSubPermitGroup: (name: string, optionName: string) => {
				const permitGroup = get().userData.permit_groups?.[name];

				return typeof permitGroup === 'boolean' ? permitGroup : permitGroup?.[optionName];
			},
			checkFieldPermitGroup: (name: string, fieldName: string, pageName: string) => {
				const permitGroup = get().userData.permit_groups?.[name];

				if (permitGroup) {
					if (pageName === 'store' && name === 'save' && fieldName === 'external_id') {
						return true;
					}

					if (typeof permitGroup === 'boolean') {
						return permitGroup;
					}

					return permitGroup[fieldName] ?? permitGroup[pageName];
				}

				return true;
			},
		}),
		{ name: 'userStore' }
	)
);

// Это в компонентах
export const useUser = () => useUserStore((state) => state.userData.user);
export const useUserConstants = () => useUserStore((state) => state.userData.constants);
export const useUserDomainConfigs = () => useUserStore((state) => state.userData.constants.domain_configs);
export const useCheckExp = (...exps: ExpName[]) => useUserStore((state) => state.checkExp(exps));

export const useUserPermits = () => useUserStore((state) => state.userData.permits);
export const useCheckPermit = (...permits: string[]) => useUserStore((state) => state.checkPermit(permits));
export const useCheckFieldPermit = (fieldName: string, pageName: string, permitName: string = 'save') =>
	useUserStore((state) => state.checkFieldPermit(fieldName, pageName, permitName));
export const usePermitValue = (permitName: string, permits: User.Permit[]) =>
	useUserStore((state) => state.getPermitValue(permitName, permits));
export const useCheckSubPermit = (permitName: string, subPermitName: string) =>
	useUserStore((state) => state.checkSubPermit(permitName, subPermitName));
export const useUserPermitGroups = () => useUserStore((state) => state.userData.permit_groups);
export const useCheckPermitGroup = (name: string) => useUserStore((state) => state.checkPermitGroup(name));
export const useCheckSubPermitGroup = (name: string, optionName: string) =>
	useUserStore((state) => state.checkSubPermitGroup(name, optionName));
export const useCheckFieldPermitGroup = (permitName: string, fieldName: string, pageName: string) =>
	useUserStore((state) => state.checkFieldPermitGroup(permitName, fieldName, pageName));

// Это в функциях
export const updateOnlyUser = (payload: User.User) => useUserStore.getState().updateOnlyUser(payload);
export const getUserData = async (updateStore?: boolean) => await useUserStore.getState().getUserData(updateStore);
export const getUserConstants = () => useUserStore.getState().userData.constants;
export const getUserDomainConfigs = () => useUserStore.getState().userData.constants.domain_configs;
export const getUserPermits = () => useUserStore.getState().userData.permits;
export const checkExp = (...exps: ExpName[]) => useUserStore.getState().checkExp(exps);

export const checkPermit = (...permits: string[]) => useUserStore.getState().checkPermit(permits);
export const checkFieldPermit = (fieldName: string, pageName: string, permitName: string = 'save') =>
	useUserStore.getState().checkFieldPermit(fieldName, pageName, permitName);
export const getPermitValue = (permitName: string, permits: User.Permit[]) =>
	useUserStore.getState().getPermitValue(permitName, permits);
export const checkSubPermit = (permitName: string, subPermitName: string) =>
	useUserStore.getState().checkSubPermit(permitName, subPermitName);

export const getUserPermitGroups = () => useUserStore.getState().userData.permit_groups;
export const checkPermitGroup = (name: string) => useUserStore.getState().checkPermitGroup(name);
export const checkSubPermitGroup = (name: string, optionName: string) =>
	useUserStore.getState().checkSubPermitGroup(name, optionName);
export const checkFieldPermitGroup = (permitName: string, fieldName: string, pageName: string) =>
	useUserStore.getState().checkFieldPermitGroup(permitName, fieldName, pageName);
